// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotions-shared__title_Jc_Uu{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault);margin:0;text-align:center}html.app__layout--desktop .promotions-shared__title_Jc_Uu{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotions-shared__title--left_uGoC0{text-align:left}.promotions-shared__title--big_p_XwR{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotion-terms__progress_cBCYu,.promotion-terms__title_C3wQZ{margin-bottom:16px}html.app__layout--desktop .promotion-terms__progress_cBCYu{margin-bottom:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotions-shared__title": `promotions-shared__title_Jc_Uu`,
	"promotions-shared__title--left": `promotions-shared__title--left_uGoC0`,
	"promotions-shared__title--big": `promotions-shared__title--big_p_XwR`,
	"promotion-terms__progress": `promotion-terms__progress_cBCYu`,
	"promotion-terms__title": `promotion-terms__title_C3wQZ`
};
export default ___CSS_LOADER_EXPORT___;
