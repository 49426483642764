// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotions-shared__title_T2P57{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault);margin:0;text-align:center}html.app__layout--desktop .promotions-shared__title_T2P57{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotions-shared__title--left_eJ6qn{text-align:left}.promotions-shared__title--big_V6U2p{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotions_A9zuW{position:relative;padding:8px 0}html.app__layout--desktop .promotions__section-wrapper_IwVil{padding:0 0 8px}.promotions__title_J1LGR{padding:16px 0}.promotions__preloader_eRylb{margin:20px}.promotions__button_b0Ffs{margin:0 auto;text-transform:none}.promotions__list-empty_VbaC8{margin-top:40px;margin-bottom:40px}.component-wrapper_zF_zs{min-height:calc(100vh - 130px);background-color:var(--Layer0)}html.app__layout--desktop .component-wrapper_zF_zs{min-height:calc(100vh - 56px)}.loader-masked_c21G3{z-index:10;position:absolute;top:0;right:0;bottom:0;left:0;display:flex;justify-content:center;padding-top:150px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotions-shared__title": `promotions-shared__title_T2P57`,
	"promotions-shared__title--left": `promotions-shared__title--left_eJ6qn`,
	"promotions-shared__title--big": `promotions-shared__title--big_V6U2p`,
	"promotions": `promotions_A9zuW`,
	"promotions__section-wrapper": `promotions__section-wrapper_IwVil`,
	"promotions__title": `promotions__title_J1LGR`,
	"promotions__preloader": `promotions__preloader_eRylb`,
	"promotions__button": `promotions__button_b0Ffs`,
	"promotions__list-empty": `promotions__list-empty_VbaC8`,
	"component-wrapper": `component-wrapper_zF_zs`,
	"loader-masked": `loader-masked_c21G3`
};
export default ___CSS_LOADER_EXPORT___;
