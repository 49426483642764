// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.how-to-hint_sne_w{border-radius:5px}.how-to-hint__info-block_EpSwn{padding:16px 12px;background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .how-to-hint__info-block_EpSwn{width:343px;padding-right:16px;padding-left:16px;margin:12px 16px}.how-to-hint__heading_gEtqZ{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;margin:0 0 8px;color:var(--TextDefault)}.how-to-hint__text_jnlqC{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:0 0 10px;color:var(--TextPrimary)}.how-to-hint__step_XmQnC{display:flex;align-items:center;text-align:left}.how-to-hint__step-text_EGPAQ{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.how-to-hint__step-icon_saqvu{flex-shrink:0;height:32px;margin-right:8px;color:var(--TextDefault);background-color:var(--Layer2);border-radius:50%}.how-to-hint__step-icon_saqvu,.how-to-hint__step-sep_uMOwS{display:flex;align-items:center;justify-content:center;width:32px}.how-to-hint__step-sep_uMOwS{height:36px;color:var(--TextPrimary)}.how-to-hint__step-sep-icon_OL1vM{fill:currentcolor}html.app__layout--desktop .how-to-hint_sne_w{overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"how-to-hint": `how-to-hint_sne_w`,
	"how-to-hint__info-block": `how-to-hint__info-block_EpSwn`,
	"how-to-hint__heading": `how-to-hint__heading_gEtqZ`,
	"how-to-hint__text": `how-to-hint__text_jnlqC`,
	"how-to-hint__step": `how-to-hint__step_XmQnC`,
	"how-to-hint__step-text": `how-to-hint__step-text_EGPAQ`,
	"how-to-hint__step-icon": `how-to-hint__step-icon_saqvu`,
	"how-to-hint__step-sep": `how-to-hint__step-sep_uMOwS`,
	"how-to-hint__step-sep-icon": `how-to-hint__step-sep-icon_OL1vM`
};
export default ___CSS_LOADER_EXPORT___;
