// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotions__list-item_BiLqC{-webkit-text-decoration:none;text-decoration:none}.promotions__list__hWhs{display:flex;flex-direction:column;gap:16px}html.app__layout--desktop .promotions__list__hWhs{display:grid;grid-template-columns:repeat(auto-fill,minmax(280px,1fr));grid-gap:32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotions__list-item": `promotions__list-item_BiLqC`,
	"promotions__list": `promotions__list__hWhs`
};
export default ___CSS_LOADER_EXPORT___;
