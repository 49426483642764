// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .terms-steps__list_ShPmD{background-color:#0000;border-radius:5px 5px 0 0}html.app__layout--desktop .terms-steps__accordion-item_LI_qU{margin-bottom:2px;background-color:var(--Layer1);cursor:default}html.app__layout--desktop .terms-steps__accordion-item_LI_qU:first-child{overflow:hidden;border-radius:5px 5px 0 0}html.app__layout--desktop .terms-steps__accordion-item__header_sD4ok{display:inline-flex;align-items:center}html.app__layout--desktop .terms-steps__accordion-item--finished_KQuw7{position:relative;background:var(--OpacityBrandDefault)}html.app__layout--desktop .terms-steps__icon-wrapper_DiNdJ{position:relative;flex-shrink:0;width:36px;color:var(--BrandDefault);text-align:center}html.app__layout--desktop .terms-steps__icon-wrapper_DiNdJ:after{position:absolute;top:50%;left:50%;width:20px;height:20px;content:"";background-color:var(--Layer0);border-radius:50%;transform:translate(-50%,-50%)}html.app__layout--desktop .terms-steps__icon-wrapper--checked_NkjTL:after{background-color:#0000}html.app__layout--desktop .terms-steps__icon_dkxwz{z-index:1;position:relative;fill:currentcolor}html.app__layout--desktop .terms-steps__title_DzClP{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextPrimary)}html.app__layout--desktop .terms-steps__read-more_G1Rpw{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;justify-content:space-between;height:44px;padding:0 16px;color:var(--TextPrimary);cursor:pointer;background-color:var(--Layer1);border-radius:5px;transition:background-color .15s ease-in-out}html.app__layout--desktop .terms-steps__read-more-icon_u2hzz{fill:var(--TextDefault)}html.app__layout--desktop .terms-steps__read-more_G1Rpw:hover{background-color:var(--Highlight)}html.app__layout--desktop .terms-steps__progress_xmdeo{flex-grow:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terms-steps__list": `terms-steps__list_ShPmD`,
	"terms-steps__accordion-item": `terms-steps__accordion-item_LI_qU`,
	"terms-steps__accordion-item__header": `terms-steps__accordion-item__header_sD4ok`,
	"terms-steps__accordion-item--finished": `terms-steps__accordion-item--finished_KQuw7`,
	"terms-steps__icon-wrapper": `terms-steps__icon-wrapper_DiNdJ`,
	"terms-steps__icon-wrapper--checked": `terms-steps__icon-wrapper--checked_NkjTL`,
	"terms-steps__icon": `terms-steps__icon_dkxwz`,
	"terms-steps__title": `terms-steps__title_DzClP`,
	"terms-steps__read-more": `terms-steps__read-more_G1Rpw`,
	"terms-steps__read-more-icon": `terms-steps__read-more-icon_u2hzz`,
	"terms-steps__progress": `terms-steps__progress_xmdeo`
};
export default ___CSS_LOADER_EXPORT___;
