// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-rate-button_bxG2k{gap:4px;width:44px;padding:0;color:var(--TextPrimary)}@media(hover:hover)and (pointer:fine){.promotion-rate-button_bxG2k:hover{color:var(--TextDefault)}}.promotion-rate-button_bxG2k.promotion-rate-button--have-likes_sRrpz{width:auto;padding:0 20px 0 16px;border-radius:24px}.promotion-rate-button_bxG2k[disabled]:not(.promotion-rate-button--pressed_YdMhR){opacity:1}.promotion-rate-button__icon_ANQ9O{flex-shrink:0}.promotion-rate-button_bxG2k.promotion-rate-button--pressed_YdMhR{color:var(--BrandDefault);opacity:.65}.promotion-rate-button_bxG2k.promotion-rate-button--pressed_YdMhR .promotion-rate-button__label_MxPO0{color:var(--TextPrimary)}html.app__layout--desktop .promotion-rate-button_bxG2k.promotion-rate-button--pressed_YdMhR:hover:not([disabled]),html.app__layout--desktop .promotion-rate-button_bxG2k.promotion-rate-button--pressed_YdMhR:hover[disabled]{color:var(--BrandDefault);background:var(--Button)}html.app__layout--desktop .promotion-rate-button_bxG2k.promotion-rate-button--pressed_YdMhR:hover:not([disabled]) .promotion-rate-button__label_MxPO0,html.app__layout--desktop .promotion-rate-button_bxG2k.promotion-rate-button--pressed_YdMhR:hover[disabled] .promotion-rate-button__label_MxPO0{color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-rate-button": `promotion-rate-button_bxG2k`,
	"promotion-rate-button--have-likes": `promotion-rate-button--have-likes_sRrpz`,
	"promotion-rate-button--pressed": `promotion-rate-button--pressed_YdMhR`,
	"promotion-rate-button__icon": `promotion-rate-button__icon_ANQ9O`,
	"promotion-rate-button__label": `promotion-rate-button__label_MxPO0`
};
export default ___CSS_LOADER_EXPORT___;
