// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-step-progress_lFIZJ{padding:8px 16px}.terms-step-progress__footer_DOgbA{display:flex;align-items:center;justify-content:space-between;margin-top:8px}.terms-step-progress__percent_xKJTe{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--BrandDefault)}.terms-step-progress__current_azjsw{color:var(--TextDefault)}.terms-step-progress__current_azjsw,.terms-step-progress__separator_P8LmX{font-size:13px;font-weight:500;line-height:20px;letter-spacing:normal}.terms-step-progress__separator_P8LmX{margin:0 4px;color:var(--TextSecondary)}.terms-step-progress__total_kUbcG{font-size:13px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terms-step-progress": `terms-step-progress_lFIZJ`,
	"terms-step-progress__footer": `terms-step-progress__footer_DOgbA`,
	"terms-step-progress__percent": `terms-step-progress__percent_xKJTe`,
	"terms-step-progress__current": `terms-step-progress__current_azjsw`,
	"terms-step-progress__separator": `terms-step-progress__separator_P8LmX`,
	"terms-step-progress__total": `terms-step-progress__total_kUbcG`
};
export default ___CSS_LOADER_EXPORT___;
