// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-socials_fzPqx{display:flex;flex-direction:row;align-items:center;justify-content:center}.promotion-socials__icon_ymopx{margin-right:8px;color:var(--TextSecondary)}.promotion-socials__icon_ymopx:hover{color:var(--TextDefault)}.promotion-socials__icon_ymopx:last-child{margin-right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-socials": `promotion-socials_fzPqx`,
	"promotion-socials__icon": `promotion-socials__icon_ymopx`
};
export default ___CSS_LOADER_EXPORT___;
