// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numbered-list-item-progress_B7VjV{flex-shrink:0;width:36px;height:36px;border:solid 2px var(--Layer2)}.numbered-list-item-progress_B7VjV,.numbered-list-item-progress-content_bCPrp{display:inline-flex;align-items:center;justify-content:center;border-radius:100%}.numbered-list-item-progress-content_bCPrp{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:28px;height:28px;color:var(--TextDefault);background-color:var(--Highlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numbered-list-item-progress": `numbered-list-item-progress_B7VjV`,
	"numbered-list-item-progress-content": `numbered-list-item-progress-content_bCPrp`
};
export default ___CSS_LOADER_EXPORT___;
