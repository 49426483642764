// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-cashback-history__title_nQiq4{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin:24px 0 16px;color:var(--TextDefault);text-align:center}html.app__layout--desktop .promotion-cashback-history__title_nQiq4{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotion-cashback-history__list_hGoBr{display:flex;flex-direction:column;gap:2px;padding:0;margin:0;list-style:none}.promotion-cashback-history__item_ZFdo_{padding:12px;background-color:var(--Layer1);border-radius:5px;display:flex;align-items:center;justify-content:space-between}.promotion-cashback-history__item--active_kM3IJ{background-color:var(--OpacityBrandDefault)}.promotion-cashback-history__item--active_kM3IJ .promotion-cashback-history__date_lPMum{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextPrimary)}.promotion-cashback-history__amount_jQ2Pk{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--BrandDefault)}.promotion-cashback-history__label_jLhq5{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;margin:0 0 4px;color:var(--TextDefault)}.promotion-cashback-history__add_bmNnR{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextPrimary)}.promotion-cashback-history__date_lPMum{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-cashback-history__title": `promotion-cashback-history__title_nQiq4`,
	"promotion-cashback-history__list": `promotion-cashback-history__list_hGoBr`,
	"promotion-cashback-history__item": `promotion-cashback-history__item_ZFdo_`,
	"promotion-cashback-history__item--active": `promotion-cashback-history__item--active_kM3IJ`,
	"promotion-cashback-history__date": `promotion-cashback-history__date_lPMum`,
	"promotion-cashback-history__amount": `promotion-cashback-history__amount_jQ2Pk`,
	"promotion-cashback-history__label": `promotion-cashback-history__label_jLhq5`,
	"promotion-cashback-history__add": `promotion-cashback-history__add_bmNnR`
};
export default ___CSS_LOADER_EXPORT___;
