// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-rate_o6YVc{position:relative}.promotion-rate__button-feedback_rPxrj{position:absolute;right:0;bottom:100%;margin-bottom:12px;white-space:nowrap}.promotion-rate__buttons_ca65D{display:flex;gap:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-rate": `promotion-rate_o6YVc`,
	"promotion-rate__button-feedback": `promotion-rate__button-feedback_rPxrj`,
	"promotion-rate__buttons": `promotion-rate__buttons_ca65D`
};
export default ___CSS_LOADER_EXPORT___;
