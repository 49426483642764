// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leader-board-spoiler_jCN9A{overflow:hidden;-webkit-user-select:none;user-select:none;border-radius:5px}.leader-board-spoiler__header_AqrCO{display:flex;flex-direction:row;align-items:center;justify-content:space-between;height:44px;color:var(--colorPrimaryL50);cursor:pointer;background-color:var(--Layer1);box-shadow:none}.leader-board-spoiler__header--open_LiC5W{color:var(--colorWhite)}.leader-board-spoiler__title_nCiQn{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:inline-block;margin-left:12px;overflow:hidden;color:var(--TextPrimary);text-overflow:ellipsis;white-space:nowrap}.leader-board-spoiler__indicator_E7fmj{display:flex;align-items:center;justify-content:center;margin-right:13px;color:var(--TextDefault)}.leader-board-spoiler__content_IGN9t{position:relative}.leader-board-spoiler__content_IGN9t :deep(.leader-board__header_qmR7n){margin-top:2px;border-radius:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leader-board-spoiler": `leader-board-spoiler_jCN9A`,
	"leader-board-spoiler__header": `leader-board-spoiler__header_AqrCO`,
	"leader-board-spoiler__header--open": `leader-board-spoiler__header--open_LiC5W`,
	"leader-board-spoiler__title": `leader-board-spoiler__title_nCiQn`,
	"leader-board-spoiler__indicator": `leader-board-spoiler__indicator_E7fmj`,
	"leader-board-spoiler__content": `leader-board-spoiler__content_IGN9t`,
	"leader-board__header": `leader-board__header_qmR7n`
};
export default ___CSS_LOADER_EXPORT___;
